import React, {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import {Bar} from 'react-chartjs-2'
import {map} from 'lodash'
import {getJSON} from 'utils'

export default function ProductEditor({
  product,
  categories,
  editId,
  onUpdate,
  onSave,
}) {
  const [sales, setSales] = useState([])
  const updateValue = useCallback(
    ({target: {value, name}}) => {
      onUpdate(name, value)
    },
    [onUpdate]
  )

  const toggleValue = useCallback(
    ({target: {id, checked}}) => {
      onUpdate(id, checked)
    },
    [onUpdate]
  )

  useEffect(() => {
    const fetchSales = async () => {
      if (product.id === '') {
        return
      }

      const res = await getJSON(`/inventory/sales/${product.id}`)
      setSales(res)
    }

    fetchSales()
  }, [product])

  const changeCategory = useCallback(
    event => onUpdate('category', event.target.value),
    [onUpdate]
  )

  const categoryList = map(categories, (name, id) => (
    <option key={id} value={id}>
      {name}
    </option>
  ))

  const changeWarehouse = useCallback(
    event => onUpdate('warehouse', event.target.value),
    [onUpdate]
  )

  const salesData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Retails',
        data: sales, // [1, 2, 100, 50, 123, 0, 1, 2, 3, 4, 5, 7],
      },
    ],
  }

  return (
    <div id="edit" className="form">
      {editId && (
        <div className="row">
          <div className="hdr">id</div>
          <div className="val">
            <input
              type="text"
              name="id"
              value={product.id}
              onChange={updateValue}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="hdr">name</div>
        <div className="val">
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={updateValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="hdr">producer</div>
        <div className="val">
          <input
            type="text"
            name="producer"
            value={product.producer}
            onChange={updateValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="hdr">ean</div>
        <div className="val">
          <input
            type="text"
            name="ean"
            value={product.ean}
            onChange={updateValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="hdr">size</div>
        <div className="val">
          <input
            type="text"
            name="bottleSize"
            placeholder="Bottle size"
            value={product.bottleSize}
            onChange={updateValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="hdr">alc.-%</div>
        <div className="val">
          <input
            type="text"
            name="alc"
            value={product.alc}
            onChange={updateValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="hdr">category</div>
        <div className="val">
          <select onChange={changeCategory} value={product.category}>
            {categoryList}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="checkbox">
          <label htmlFor="alkoApproved">
            <input
              type="checkbox"
              id="alkoApproved"
              checked={product.alkoApproved}
              onChange={toggleValue}
            />
            Alko approved
          </label>
          <label htmlFor="eanRequired">
            <input
              type="checkbox"
              id="eanRequired"
              checked={product.eanRequired}
              onChange={toggleValue}
            />
            EAN required
          </label>
        </div>
      </div>
      <div className="row">
        <div className="checkbox">
          <label htmlFor="taxFree">
            <input
              type="checkbox"
              id="taxFree"
              checked={product.taxFree}
              onChange={toggleValue}
            />
            Tax free
          </label>
          <label htmlFor="taxExempt">
            <input
              type="checkbox"
              id="taxExempt"
              checked={product.taxExempt}
              onChange={toggleValue}
            />
            Bottling tax free
          </label>
        </div>
      </div>
      <div className="row">
        <div className="checkbox">
          <label htmlFor="legacy">
            <input
              type="checkbox"
              id="legacy"
              checked={product.legacy}
              onChange={toggleValue}
            />
            Legacy product
          </label>
          <label htmlFor="bio">
            <input
              type="checkbox"
              id="bio"
              checked={product.bio}
              onChange={toggleValue}
            />
            Bio
          </label>
        </div>
      </div>

      <h3>Inventory</h3>

      <div className="row">
        <div className="hdr">rtl size</div>
        <div className="val">
          <input
            type="text"
            name="retailSize"
            size="5"
            placeholder="Retail package size"
            value={product.retailSize}
            onChange={updateValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="hdr">bottles</div>
        <div className="val">
          <input
            type="text"
            name="bottles"
            size="5"
            value={product.bottles}
            onChange={updateValue}
          />
        </div>
      </div>
      <h3>Storage location</h3>
      <div className="row">
        <div className="hdr">warehouse</div>
        <div className="val">
          <select onChange={changeWarehouse} value={product.warehouse}>
            <option key="bewe" value="bewe">
              Beweship
            </option>
          </select>
        </div>
      </div>
      {/* <div className="row">
        <div className="hdr">area</div>
        <div className="val">
          <input
            type="text"
            name="storageArea"
            size="5"
            placeholder="Storage area"
            value={product.storageArea}
            onChange={updateValue}
          />
        </div>
      </div> */}
      <div className="row">
        <div className="hdr">shelf</div>
        <div className="val">
          <input
            type="text"
            name="storageShelf"
            size="5"
            placeholder="Storage shelf"
            value={product.storageShelf}
            onChange={updateValue}
          />
        </div>
      </div>
      <h3>Price</h3>
      <div className="row">
        <div className="hdr">in</div>
        <div className="val">
          <input
            type="text"
            name="priceIn"
            size="5"
            placeholder="excl. VAT"
            value={product.priceIn}
            onChange={updateValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="hdr">out (inc. VAT)</div>
        <div className="val">
          <input
            type="text"
            name="priceOut"
            size="5"
            placeholder="incl. VAT"
            value={product.priceOut}
            onChange={updateValue}
          />
        </div>
      </div>
      <button className="button-wide" type="submit" onClick={onSave}>
        Save
      </button>
      {sales.length && (
        <>
          <h3>Sales</h3>
          <Bar
            data={salesData}
            width={100}
            height={50}
            options={{
              maintainAspectRatio: true,
            }}
          />
        </>
      )}
    </div>
  )
}

ProductEditor.propTypes = {
  product: PropTypes.shape().isRequired,
  categories: PropTypes.shape().isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editId: PropTypes.bool.isRequired,
}
