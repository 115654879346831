import React, {useState} from 'react'
import {Icon} from 'react-icons-kit'
import {sub, format} from 'date-fns'
import {padStart, range} from 'lodash'
import {iosPaperOutline} from 'react-icons-kit/ionicons/iosPaperOutline'

import {getBaseURL} from 'utils'

const SalesReport = () => {
  const now = new Date() // sub(new Date(), {months: 1})
  const [year, setYear] = useState(format(now, 'yyyy'))
  const [month, setMonth] = useState(format(now, 'MM'))

  const changeYear = evt => {
    setYear(evt.target.value)
  }

  const changeMonth = evt => {
    setMonth(evt.target.value)
  }

  const startYear = 2020
  const endYear = now.getFullYear()

  const yearList = range(startYear, endYear + 1).map(y => (
    <option key={y} value={y}>
      {y}
    </option>
  ))

  const startMonth = 1
  const endMonth = year === `${now.getFullYear()}` ? now.getMonth() + 1 : 12

  const monthList = range(startMonth, endMonth + 1).map(m => {
    const fmtMonth = padStart(m, 2, '0')

    return (
      <option key={fmtMonth} value={fmtMonth}>
        {fmtMonth}
      </option>
    )
  })

  return (
    <div className="sales-report">
      <div>
        <select className="year" value={year} onChange={changeYear}>
          {yearList}
        </select>

        <select className="month" value={month} onChange={changeMonth}>
          {monthList}
        </select>
      </div>
      {/* <div className="link">
        <a href={`${getBaseURL()}/report/${year}/${month}?inta`}>
          <Icon size={30} icon={iosPaperOutline} />
          valvira-inta-{year}-{month}.xls
        </a>
      </div> */}
      <div className="link">
        <a href={`${getBaseURL()}/report/${year}/${month}?bewe`}>
          <Icon size={30} icon={iosPaperOutline} />
          valvira-{year}-{month}.xls
        </a>
      </div>
      <div className="link">
        <a href={`${getBaseURL()}/report/${year}/${month}?tulli`}>
          <Icon size={30} icon={iosPaperOutline} />
          tulli-{year}-{month}.xls
        </a>
      </div>
      <div className="link">
        <a href={`${getBaseURL()}/report/${year}/${month}?bio`}>
          <Icon size={30} icon={iosPaperOutline} />
          bio-{year}-{month}.xls
        </a>
      </div>
    </div>
  )
}

export default SalesReport
